import React from "react"
import { useIntl } from "gatsby-plugin-intl";
import {
  Container,
  makeStyles,
} from '@material-ui/core';
import Layout from "../layouts"
import SEO from "../layouts/seo";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '160px',
    marginBottom: '60px',
  },
}));


const CareersPage = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "navigation.contact_us" })}
      />
      <Container maxWidth="lg" className={classes.root}>
      </Container>
    </Layout>
  )
}
export default CareersPage

